import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NProgress from 'nprogress';
import Common from '../commons/Common.mjs';
import TestApi from '../api/TestApi';
import AuthService from '../services/AuthService';
import Consts from '../consts/Consts';
import { useAuth } from '../providers/AuthProvider';
import { useAlert } from '../providers/AlertProvider';
import AlertType from '../consts/AlertType';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [shouldRemember, setShouldRemember] = useState(true);

  const { t, i18n } = useTranslation();

  const { showAlert } = useAlert();
  
  const navigate = useNavigate();

  const { handleLocalLogin } = useAuth()

  useEffect(() => {
    handleOnLoad();
  }, [])

  async function handleOnLoad() {
    handleFormOnLoad();
  }

  async function checkAuth() {
    try {
      let checkAuthResponse = await AuthService.check();
      if (checkAuthResponse?.message === Consts.OK && checkAuthResponse?.userData) {
        handleLocalLogin(checkAuthResponse?.userData);
        navigate("/");
      }
    } catch(e) {
      navigate("/login");
    }
  }

  async function simulateLoginType() {
    setEmail(" ")
    setPassword(" ")
    await Common.sleep(500);
    setEmail("")
    setPassword("")
  }

  async function testHello() {
    try {
      let res = await TestApi.hello()  
      console.log(res.data)
    } catch (e) {
      console.error("unable to hello");
    }
  }

  async function handleFormOnLoad() {
    await simulateLoginType()
    await Common.sleep(500)
    setShowForm(true)
  }

  function getFormClasses() {
    let classes = []
    if (showForm) {
      classes.push("show")
    }

    return classes.join(" ")
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleShouldRemeberChange(e) {
    setShouldRemember(e.target.checked);
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    processLogin();
  }

  async function processLogin() {
    let loginData = {email, password, shouldRemember};
    try {
      const loginResult = await AuthService.login(loginData);
      if (loginResult?.message === Consts.OK) {
        if (loginResult?.userData) {
          handleLocalLogin(loginResult?.userData);
          await Common.sleep(100);
          navigate("/");
        }
      }
    } catch(e) {
      handleLoginError(e);
    }
  }

  function handleLoginError(e) {
    if (e.message === "INVALID_CREDS") {
      showAlert("Invalid Credentials", AlertType.DANGER);
      return;
    }

    showAlert("Something Went Wrong", AlertType.DANGER);
  }

  function renderForm() {
    return (
      <form className={`${getFormClasses()}`} onSubmit={handleFormSubmit}>
        {/* <h1 className="h3 mb-3 fw-normal">WUS OPS</h1> */}
        <img className='login-logo' src="/wusops-logo-red.png"/>
        <div className="form-floating">
          <input type="email"
                 className="form-control"
                 placeholder="name@example.com"
                 autoComplete='off'
                 onChange={handleEmailChange}
                 value={email} />
          <label htmlFor="floatingInput">{t("login.email")}</label>
        </div>
        <div className="form-floating">
          <input type="password"
                 className="form-control"
                 placeholder="Password"
                 autoComplete='off'
                 onChange={handlePasswordChange}
                 value={password} />
          <label htmlFor="floatingPassword">{t("login.password")}</label>
        </div>

        <div className="form-check text-start my-3">
          <input className="form-check-input" 
                 type="checkbox" 
                 id="shouldRemeberCheckbox"
                 value={shouldRemember} 
                 onChange={handleShouldRemeberChange} />
          <label className="form-check-label" htmlFor="shouldRemeberCheckbox">
            {t("login.rememberMe")}
          </label>
        </div>
        <button className="btn btn-primary w-100 py-2" type="submit">{t("login.signIn")}</button>
        <p className="mt-5 mb-3 text-body-secondary">{t("footer.copyRight")}</p>
      </form>
    )

  }

  return (
    <div className="container py-5" id="login">
      <main className="form-signin m-auto">
        {renderForm()}
      </main>
    </div>
  );
};

export default Login;
