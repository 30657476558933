import axios from 'axios';
import NProgress from 'nprogress';
import Common from '../commons/Common.mjs';

class ApiClient {
  static baseUrl = "http://localhost:3002";
  static initialized = false;
  static axiosInstance = axios.create({
    timeout: 10000,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  static init() {
    if (ApiClient.initialized) {
      return;
    }

    ApiClient.initialized = true;

    if (Common.isProd()) {
      ApiClient.baseUrl = "";
    }

    ApiClient.axiosInstance.interceptors.request.use(function (config) {
      NProgress.start();
      return config;
    }, function (error) {
      NProgress.done();
      return Promise.reject(error);
    });

    ApiClient.axiosInstance.interceptors.response.use(
      (response) => {
        NProgress.done();
        return response
      },
      (error) => {
        NProgress.done();
        if (error.response) {
          if (error.response.status === 401) {
            window.location.href = '/login';
          }
        } else {
          console.error('Network error or no response:', error);
        }
        return Promise.reject(error);
      }
    );
  }

  static async get(url, config = {}, baseUrl = ApiClient.baseUrl) {
    return ApiClient.axiosInstance.get(baseUrl + url, config);
  }

  static async post(url, data = {}, config = {}, baseUrl = ApiClient.baseUrl) {
    return ApiClient.axiosInstance.post(baseUrl + url, data, config);
  }

  static async put(url, data = {}, config = {}, baseUrl = ApiClient.baseUrl) {
    return ApiClient.axiosInstance.put(baseUrl + url, data, config);
  }

  static async delete(url, config = {}, baseUrl = ApiClient.baseUrl) {
    return ApiClient.axiosInstance.delete(baseUrl + url, config);
  }

  static async patch(url, data = {}, config = {}, baseUrl = ApiClient.baseUrl) {
    return ApiClient.axiosInstance.patch(baseUrl + url, data, config);
  }

  static getResponseDataFromErrorResponse(e) {
    if (typeof e?.response?.data?.message !== "undefined") {
      return e?.response?.data?.message
    }

    return e.code || e
  }

  static getResponseDataFromRequest(response) {
    if (typeof response?.data !== "undefined") {
      return response?.data
    }

    return null;
  }
}

ApiClient.init()

export default ApiClient;
