import ApiClient from "./ApiClient";

class AuthApi {
  static async login(data = {}) {
    return ApiClient.post("/auth/login", data);
  }

  static async check(data = {}) {
    return ApiClient.post("/auth/check", data);
  }

  static async logout(data = {}) {
    return ApiClient.post("/auth/logout", data);
  }
}

export default AuthApi;