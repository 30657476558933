import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';

const Logout = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    handleLogout()
  }, [])

  async function handleLogout() {
    await AuthService.logout();
    navigate("/login");
  }

  return (
    <div></div>
  );
};

export default Logout;
