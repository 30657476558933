import ApiClient from "../api/ApiClient";
import AuthApi from "../api/AuthApi"
import BulkApi from "../api/BulksApi";

class BulkService {
  static async getShopifyBulk() {
    try {
      let response = await BulkApi.getShopifyBulk();
      return response?.data?.currentBulk
    } catch(e) {
      throw new Error(ApiClient.getResponseDataFromErrorResponse(e));
    }
  }

  static async startCreditStoreBulk() {
    try {
      let response = await BulkApi.startCreditBalanceBulk();
      return response?.data
    } catch(e) {
      throw new Error(ApiClient.getResponseDataFromErrorResponse(e));
    }
  }

  static async processCreditStoreResult() {
    try {
      let response = await BulkApi.processCreditStoreResult();
      return response?.data
    } catch(e) {
      throw new Error(ApiClient.getResponseDataFromErrorResponse(e));
    }
  }
}

export default BulkService