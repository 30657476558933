import ApiClient from "./ApiClient";

class BulkApi {
  static async getShopifyBulk() {
    return ApiClient.get("/shopify-bulks/");
  }

  static async startCreditBalanceBulk() {
    return ApiClient.post("/shopify-bulks/run-store-credit-balance");
  }

  static async processCreditStoreResult() {
    return ApiClient.post("/shopify-bulks/process-store-credit-balance", {}, { timeout: 300000 });
  }
}

export default BulkApi;