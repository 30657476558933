import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      general: {
        runBtn: "Run",
        processResultBtn: "Process Result",
        processing: "Processing ...",
        alreadyProcessing: "Already Processing",
        copiedSuccess: "Copied",
        copiedError: "Unable to copy",
        copy: "Copy",
        downloadCSV: "Download CSV",
        result: "Result"
      },
      home: {
        title: "Operations",
        scbTitle: "Store Credit Balance",
        scbDescription: "This operation will calculate the total credit balance that currently exists in the store.",
      },
      scb: {
        title: "Store Credit Balance",
        npo: "No. Processed Objects",
        lastUpdate: "Last Update",
        status: "Status",
        balanceResult: "Balance Result",
        transactionsResult: "Transactions Result"
      },
      header: {
        ops: "Ops",
        logout: "Logout"
      },
      footer: {
        copyRight: "\u00A9 2024, WayUp Sports."
      },
      login: {
        email: "Email Addres",
        password: "Password",
        rememberMe: "Remember me",
        signIn: "Sign in"
      }
    },
  }
};

i18n
  .use(initReactI18next) // Bind i18n to React
  .init({
    resources, // Add translation resources
    lng: "en",
    fallbackLng: "en", // Default language if the user's language is not available
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
