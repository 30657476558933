import React, { createContext, useState, useContext } from "react";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const showAlert = (message, type = "info", hideTimeout = 3000) => {
    const id = Date.now();
    setAlerts((prevAlerts) => [...prevAlerts, { id, message, type }]);

    setTimeout(() => {
      removeAlert(id);
    }, hideTimeout);
  };

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  const renderAlert = (alert) => {
    return (
      <div key={alert.id} className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
        {alert.message}
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => removeAlert(alert.id)}
        ></button>
      </div>
    )
  }

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert && (
        <div className='alert-object'>
          <div className='alert-wrapper'>
          {alerts.map((alert) => (
            renderAlert(alert)
          ))}
          </div>
        </div>
      )}
    </AlertContext.Provider>
  );
};
