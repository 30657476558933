import React, { useEffect, useState } from 'react';
import OpStatusLine from '../components/OpStatusLine';
import BulkService from '../services/BulkService';
import Common from '../commons/Common.mjs';
import { useAlert } from '../providers/AlertProvider';
import AlertType from '../consts/AlertType';
import { useTranslation } from 'react-i18next';

const BULK_ID = "CREDIT_BALANCE_JOB"

const StoreCreditBalance = () => {
  const [currentBulk, setCurrentBulk] = useState(null);
  const [currentBalanceOutput, setCurrentBalanceOutput] = useState(null);
  const [currentTransactionsOuptut, setCurrentTransactionsOutput] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { t, i18n } = useTranslation();

  const { showAlert } = useAlert();

  useEffect(() => {
    fetchCurrenBulk();
  }, []);

  useEffect(() => {
    handleCurrentBulkChange()
  }, [currentBulk])

  async function handleCurrentBulkChange() {
    if (currentBulk && currentBulk.status === "RUNNING") {
      setTimeout(() => {
        fetchCurrenBulk();
      }, 10 * 1000)
    }
  }

  async function startBulk() {
    await BulkService.startCreditStoreBulk();
    await Common.sleep(100);
    fetchCurrenBulk();
  }

  async function fetchCurrenBulk() {
    let bulkResponse = await BulkService.getShopifyBulk();
    
    if (!bulkResponse) {
      return;
    }

    if (bulkResponse.query && bulkResponse.query.includes("storeCreditAccounts")) {
      bulkResponse.lastUpdateDate = new Date().toLocaleDateString()  +" "+ new Date().toLocaleTimeString()
      setCurrentBulk(bulkResponse);
    } else {
      setCurrentBulk(null);
    }
  }

  async function processResult() {
    try {
      setCurrentBalanceOutput(null);
      setCurrentTransactionsOutput(null);

      if (isProcessing) {
        showAlert(t("general.alreadyProcessing"), AlertType.INFO, 2000);
        return;
      }

      setIsProcessing(true);

      let result = await BulkService.processCreditStoreResult();
      
      setIsProcessing(false);

      if (!result) {
        return;
      }

      if (!result.output) {
        return;
      }

      if (result.output.balancesOutput) {
        setCurrentBalanceOutput(result.output.balancesOutput);
      }

      if (result.output.transactionsOutput) {
        setCurrentTransactionsOutput(result.output.transactionsOutput);
      }
    } catch(e) {
      setIsProcessing(false);
    }
  }

  function handleRunClick() {
    startBulk()
  }

  function handleProcessResultClick() {
    processResult();
  }

  function renderRunButton() {
    if (currentBulk && currentBulk?.status === "RUNNING") {
      return null;
    }

    return (
      <button type="button" className="btn btn-primary run-credit-store-button" onClick={handleRunClick}>{t("general.runBtn")}</button>
    )
  }

  function renderProcessButton() {
    if (!currentBulk) {
      return null;
    }

    if (currentBulk?.status !== "COMPLETED") {
      return null;
    }

    var buttonText = t("general.processResultBtn");

    if (isProcessing) {
      buttonText = t("general.processing");
    }

    return (
      <button type="button" className="btn btn-primary" onClick={handleProcessResultClick}>{ buttonText }</button>
    )
  }

  function renderRunningStatus() {
    if (!currentBulk) {
      return null;
    }

    if (!currentBulk?.status) {
      return null;
    }
    
    return (
      <OpStatusLine title={t("scb.status")} value={currentBulk?.status}/>
    )
  }

  function renderNoObjects() {
    if (!currentBulk) {
      return null;
    }

    if (!currentBulk?.objectCount) {
      return null;
    }

    return (
      <OpStatusLine title={t("scb.npo")} value={currentBulk.objectCount}/>
    )
  }

  function renderLastUpdate() {
    if (!currentBulk) {
      return null;
    }

    if (typeof currentBulk.lastUpdateDate === "undefined") {
      return null;
    }

    return(
      <OpStatusLine title={t("scb.lastUpdate")} value={currentBulk.lastUpdateDate}/>
    ) 
  }

  async function handleCopyBalanceOutput(e) {
    e.preventDefault();
    
    if (!currentBalanceOutput) {
      return;
    }

    if (typeof currentBalanceOutput.textOutput !== "string") {
      return;
    }

    try {
      await Common.copyToClipboard(currentBalanceOutput.textOutput)
      showAlert(t("general.copiedSuccess"), AlertType.SUCCESS);  
    } catch (e) {
      showAlert(t("general.copiedError"), AlertType.DANGER);  
    }
  }

  async function handleCopyTransactionsOutput(e) {
    e.preventDefault();
    
    if (!currentTransactionsOuptut) {
      return;
    }

    if (typeof currentTransactionsOuptut.transactionsCSV !== "string") {
      return;
    }

    try {
      await Common.copyToClipboard(currentTransactionsOuptut.transactionsCSV)
      showAlert(t("general.copiedSuccess"), AlertType.SUCCESS);  
    } catch (e) {
      showAlert(t("general.copiedError"), AlertType.DANGER);  
    }
  }

  function handleDownloadBalanceClick(e) {
    e.preventDefault();

    if (!currentBalanceOutput) {
      return;
    }

    if (typeof currentBalanceOutput.csvOutput !== "string") {
      return;
    }

    let filename = "store_credit_balance_" + new Date().toISOString() + ".csv";

    Common.downloadCSVFromStr(currentBalanceOutput.csvOutput, filename);
  } 

  function handleDownloadTransactionsClick(e) {
    e.preventDefault();

    if (!currentTransactionsOuptut) {
      return;
    }

    if (typeof currentTransactionsOuptut.transactionsCSV !== "string") {
      return;
    }

    let filename = "store_credit_transactions_" + new Date().toISOString() + ".csv";

    Common.downloadCSVFromStr(currentTransactionsOuptut.transactionsCSV, filename);
  } 

  function renderBalanceResult() {
    if (!currentBalanceOutput) {
      return null;
    }

    return (
      <div className='op-credit-result mt-4'>
        <div className='op-credit-result-title'>{t("scb.balanceResult")}</div>
        <div className='op-credit-result-actions mt-2'>
          <a onClick={handleCopyBalanceOutput} className='op-credit-result-action' href="#">{t("general.copy")}</a>
          <a onClick={handleDownloadBalanceClick} className='op-credit-result-action' href="#">{t("general.downloadCSV")}</a>
        </div>
        <div className='op-credit-result-ouput mt-3'>
          <pre>{currentBalanceOutput.textOutput}</pre>
        </div>
      </div>
    )
  }

  function renderTransactionsResult() {
    if (!currentTransactionsOuptut) {
      return null;
    }

    return (
      <div className='op-credit-result mt-4'>
        <div className='op-credit-result-title'>{t("scb.transactionsResult")}</div>
        <div className='op-credit-result-actions mt-2'>
          <a onClick={handleCopyTransactionsOutput} className='op-credit-result-action' href="#">{t("general.copy")}</a>
          <a onClick={handleDownloadTransactionsClick} className='op-credit-result-action' href="#">{t("general.downloadCSV")}</a>
        </div>
        <div className='op-credit-result-ouput mt-3'>
          <pre>{currentTransactionsOuptut.transactionsCSV}</pre>
        </div>
      </div>
    )
  }

  return (
    <div className="container py-5" id="store-credit-balance">
      <h2 className="pb-2 border-bottom">{t("scb.title")}</h2>
      
      <div className='op-container mt-4'>
        <div className='op-status-container mb-3'>
          {renderRunningStatus()}
          {renderNoObjects()}
          {renderLastUpdate()}
        </div>
        <div className='op-buttons-container'>
          {renderRunButton()}
          {renderProcessButton()}
        </div>
        {renderBalanceResult()}
        {renderTransactionsResult()}
      </div>
    </div>
  );
};

export default StoreCreditBalance;
