import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import AuthService from "../services/AuthService";
import Consts from "../consts/Consts";


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, handleLocalLogin } = useAuth();
  const navigate = useNavigate()
  useEffect(() => {
    checkAuth()
  }, [])

  async function checkAuth() {
    if (isAuthenticated) {
      return;
    }

    try {
      let checkAuthResponse = await AuthService.check();
      if (checkAuthResponse?.message === Consts.OK && checkAuthResponse?.userData) {
        handleLocalLogin(checkAuthResponse?.userData);
      }
    } catch(e) {
      navigate("/login")
    }
  }

  if (!isAuthenticated) {
    //return <Navigate to="/login" />;
    return null;
  }

  return children;
};

export default ProtectedRoute;
