import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer>
        <div className="container">
          <p className="mt-5 mb-3 text-body-secondary">{t("footer.copyRight")}</p>
        </div>
    </footer>
  );
};

export default Footer;
