import React from 'react';
import { Link } from 'react-router-dom';

const OperationTile = ({title, description, callToActionText, iconID, link}) => {
  return (
    <div className="operation-tile feature col">
      <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
        <svg className="operation-icon bi" width="1em" height="1em">
          <use xlinkHref={`/icons/bootstrap-icons.svg#${iconID}`}></use>
        </svg>
      </div>
      <h3 className="fs-2 text-body-emphasis">{title}</h3>
      <p>{description}</p>
      <Link to={`${link}`} className="icon-link">
        {callToActionText}
        <svg className="bi"><use xlinkHref="/icons/bootstrap-icons.svg#chevron-right"></use></svg>
      </Link>
    </div>
  );
};

export default OperationTile;
