import ApiClient from "../api/ApiClient";
import AuthApi from "../api/AuthApi"

class AuthService {
  static async login(loginData) {
    try {
      let response = await AuthApi.login(loginData);
      let data = ApiClient.getResponseDataFromRequest(response);
      return data
    } catch(e) {
      throw new Error(ApiClient.getResponseDataFromErrorResponse(e));
    }
  }

  static async check() {
    try {
      let response = await AuthApi.check();
      let data = ApiClient.getResponseDataFromRequest(response);
      return data
    } catch(e) {
      throw new Error(ApiClient.getResponseDataFromErrorResponse(e));
    }
  }

  static async logout() {
    try {
      let response = await AuthApi.logout();
      let data = ApiClient.getResponseDataFromRequest(response);
      return data
    } catch(e) {
      throw new Error(ApiClient.getResponseDataFromErrorResponse(e));
    }
  }
}

export default AuthService