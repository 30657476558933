import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function handleLogoutClick(e) {
    e.preventDefault();
    handleLogout()
  }

  async function handleLogout() {
    await AuthService.logout();
    navigate("/login");
  }

  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-primary main-header">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img className='header-logo' src="/wusops-logo-white.png"/>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                {t("header.ops")}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/logout" className="nav-link" onClick={handleLogoutClick}>
              {t("header.logout")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
