class Common {
  static sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  static async copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      throw new Error(err);
    }
  }

  static downloadCSVFromStr = (csvString, fileName) => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static isProd() {
    return process.env.NODE_ENV === "production";
  }
}

export default Common