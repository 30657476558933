import React from 'react';

const OpStatusLine = ({title, value}) => {
  return (
    <div className='op-status-line'>
      <span className='op-status-line-title'>{title}:</span>
      <span className='op-status-line-value'>{value}</span>
    </div>
  );
};

export default OpStatusLine;
