import React, { useEffect } from 'react';
import OperationTile from '../components/OperationTile';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t, i18n } = useTranslation();

  const operationTilesList = [
    {
      id: "1",
      title: t("home.scbTitle"),
      description: t("home.scbDescription"),
      callToActionText: t("general.runBtn"),
      icon: "piggy-bank",
      link: "/ops/store-credit-balance"
    }
  ]

  return (
    <div className="container py-5" id="home">
      <h2 className="pb-2 border-bottom">{t("home.title")}</h2>
      <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
        {operationTilesList.map((operationTile) => (
          <OperationTile key={operationTile.id}
                         title={operationTile.title}
                         description={operationTile.description}
                         callToActionText={operationTile.callToActionText}
                         iconID={operationTile.icon}
                         link={operationTile.link}/>
        ))}
        
      </div>
    </div>
  );
};

export default Home;
