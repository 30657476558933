import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NProgress from 'nprogress';
import Header from './components/Header.js';
import Home from './pages/Home.js';
import StoreCreditBalance from './pages/StoreCreditBalance.js';
import Login from './pages/Login.js';
import Footer from './components/Footer.js';
import { AuthProvider, useAuth } from './providers/AuthProvider.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import Logout from './pages/Logout.js';
import { AlertProvider } from './providers/AlertProvider.js';

function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </AlertProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isLogoutPage = location.pathname === '/logout';

  const { isAuthenticated } = useAuth()

  useEffect(() => {
    handleMainClass();
  }, [location.pathname]);

  function handleMainClass() {
    if (location.pathname === '/login' || location.pathname === '/logout') {
      document.body.classList.add('no-header-added');
    } else {
      document.body.classList.remove('no-header-added');
    }
  }

  function shouldHideHeaderFooter() {
    return isLoginPage || !isAuthenticated || isLogoutPage;
  }

  function renderHeader() {
    if (shouldHideHeaderFooter()) {
      return null
    }

    return (
      <Header />
    )
  }

  function renderFooter() {
    if (shouldHideHeaderFooter()) {
      return null
    }

    return (
      <Footer />
    )
  }

  return (
    <div>
      {renderHeader()}
      <main className='app-main'>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } />
          <Route path="/ops/store-credit-balance" element={
            <ProtectedRoute>
              <StoreCreditBalance />  
            </ProtectedRoute>
          } />
        </Routes>
      </main>
      {renderFooter()}
    </div>
  );
}

export default App;
